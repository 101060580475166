// ページ内スクロール
$('a[href^="#"]').click(function () {
  const speed = 600;
  let href = $(this).attr("href");
  let target = $(href == "#" || href == "" ? "html" : href);
  let position = target.offset().top;

  let margin = 70
  const wWidth = $(window).width()
  if(wWidth > 768){
    margin = 120
  }
  $("body,html").animate({ scrollTop: (position -margin) }, speed, "swing");
  return false;
});