$(function(){

  $(window).on('load resize',function(){
    let wWidth = $(window).width()
    if(wWidth > 768){
      $('.js-acc-menu').hide()
      $('.js-acc-button').removeClass('open')
    }
  })

  let parents = $('.js-acc-button')
  parents.on('click',function(){
    let target = $(this).parent('div').find('.js-acc-menu')
    target.slideToggle(300)
    $(this).toggleClass('open')
  })
})