
const mySwiperPlan = new Swiper(".js-slider-plan ", {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 20,
  speed: 1000,
  breakpoints: {
    1024: {
      slidesPerView: 5,
      spaceBetween: 10,
      centeredSlides: false,
    }
  },
  pagination: {
    el: ".p-top_plan-pagenation",
    clickable: true,
  }
});

const mySwiperMenu = new Swiper(".js-slider-menu ", {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 20,
  speed: 1000,
  // mousewheel: true,
  breakpoints: {
    1024: {
      spaceBetween: 40,
      centeredSlides: true,
    }
  },
  pagination: {
    el: ".p-top_menu-pagenation",
    clickable: true,
  },

  navigation: {
    prevEl: ".swiper-menu-button-next",
    nextEl: ".swiper-menu-button-prev"
  },
});


const mySwiperSalon = new Swiper(".js-slider-salon ", {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 20,
  speed: 1000,
  breakpoints: {
    1024: {
      spaceBetween: 40,
      centeredSlides: true,
    }
  },
  pagination: {
    el: ".p-top_salon-pagenation",
    clickable: true,
  },

  navigation: {
    prevEl: ".swiper-salon-button-next",
    nextEl: ".swiper-salon-button-prev"
  },
});

const mySwiperModel = new Swiper(".js-slider-model ", {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 20,
  speed: 1000,
  breakpoints: {
    1024: {
      slidesPerView: 5,
      spaceBetween: 10,
      centeredSlides: false,
    }
  },
  pagination: {
    el: ".c-plan_pagenation",
    clickable: true,
  }
});