$(function () {
  var openFlag = false;
  $('.js-nav-button').on('click', function () {
    $(this).toggleClass('close');
    $('.js-nav-menu').toggleClass('open');
    if(openFlag == false){
      $('body').addClass('open');
      openFlag = true;
    }else{
      $('body').removeClass('open');
      openFlag = false;
      $('#js-nav-scroll').scrollTop(0)
  }
  });

    // $('.js-menu-parent').on('click', function () {
    //     $(this).toggleClass('open');
    //     $(this).parent().find('.js-menu-child').slideToggle();
    // });

    // $(window).on('load resize', function(){
    //     var wWidth = $(window).width();
    //     if(wWidth > 768){
    //         $('body').removeClass('open');
    //         openFlag = false;
    //         $('.js-toggle-menu').removeClass('open');
    //     }
    // });
});