$(function(){
  if($('.js-set-year').length > 0){
    const now = new Date()
    const  nowYear = now.getFullYear()
    // console.log(nowYear)

    const startyear = nowYear - 20
    const endYear = nowYear - 54
    // console.log(startyear)
    // console.log(endYear)

    let yearOptions = `<option value="">選んでください</option>`
    for(i= startyear; i >= endYear; i--){
      let nengo = ''
      let nengoYear = ''
      switch(true){
        case i > 2018 :
          nengo = '令和'
          nengoYear = i - 2018
          break
        case i > 1988 :
          nengo = '平成'
          nengoYear = i - 1988
          break
        case i > 1925 :
          nengo = '昭和'
          nengoYear = i - 1925
          break
      }
      
      let setValue = `${i}年(${nengo}${nengoYear}年)`
      
      yearOptions += `<option value="${setValue}">${setValue}</option>`
    }
    
    $('.js-set-year').html(yearOptions)
  }
})